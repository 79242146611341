<template>
  <v-container fluid fill-height class="grey darken-3">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="black" light flat>
            <v-toolbar-title class="white--text">Zresetuj hasło</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-alert v-if="message" type="error">
                {{ message }}
              </v-alert>
              <v-alert v-else-if="success" type="success">
                {{ success }}
              </v-alert>
              <v-container v-if="!success">
                <v-row align="center" justify="center">
                  <v-col>
                    <v-text-field
                      v-model="pass"
                      label="Nowe hasło"
                      name="password"
                      type="password"
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col>
                    <v-text-field
                      v-model="repass"
                      label="Potwierdź nowe hasło"
                      name="repassword"
                      type="password"
                    />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" v-if="!hidden">
                  <v-col>
                    <v-text-field v-model="token" label="Token" name="token" type="text" />
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-card-actions>
                    <v-btn @click="reset" color="amber accent-3" class="white--text" :x-large="true"
                      >Zatwierdź</v-btn
                    >
                  </v-card-actions>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'reset_password',
    data: () => ({
      pass: null,
      repass: null,
      token: null,
      message: null,
      success: null,
      hidden: false,
    }),
    methods: {
      reset: function () {
        this.message = null;
        axios({
          url: this.$store.state.url + '/api/reset_password',
          data: {
            pass: this.pass,
            repass: this.repass,
            token: this.token,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.message = null;
              this.success =
                'Hasło zostało zmienione.\nZaraz zostaniesz przekierowany na strone logowania.';
              setTimeout(() => {
                this.$router.push('/login');
              }, 4000);
            } else {
              this.message = resp.data.message;
            }
          })
          .catch();
      },
    },
    created() {
      if (this.$route.query.token) {
        this.token = this.$route.query.token;
        this.hidden = true;
      }
    },
  };
</script>
